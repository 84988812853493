@import url("https://fonts.googleapis.com/css?family=Open+Sans|Raleway:300,400");

@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", Sans-Serif;
  color: #002b3a;
}

body {
  font-family: "Open Sans", Sans-Serif;
}

.navbar {
  background-color: #002b3a !important;
}

.manage-nav a {
  border-style: solid;
  border-color: #ffffff;
  border-width: 0;
  border-left-width: 5px;
}

.manage-nav a.active {
  border-color: #002b3a;
  color: #002b3a;
  background-color: #f8f9fa;
}

.pending-table tr {
  cursor: pointer;
}

.pending-arrow {
  visibility: hidden;
}
tr:hover .pending-arrow {
  visibility: visible;
}
